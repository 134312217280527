import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaUrl: 'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  minimalStore: 'https://minimals.cc/store',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/error/403',
  page404: '/error/404',
  page500: '/error/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    // permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking q`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },

    /* Admin Access ******************************************************************* */
    admin: {
      access: {

      }
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user/${paramCase(id)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    role: {
      root: `${ROOTS.DASHBOARD}/role`,
      new: `${ROOTS.DASHBOARD}/role/new`,
      list: `${ROOTS.DASHBOARD}/role/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/role/${paramCase(id)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/role/${id}/edit`,
    },
    permission: {
      root: `${ROOTS.DASHBOARD}/permission`,
      new: `${ROOTS.DASHBOARD}/permission/new`,
      list: `${ROOTS.DASHBOARD}/permission/list`,
      details: (slug: string) => `${ROOTS.DASHBOARD}/permission/${paramCase(slug)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/permission/${id}/edit`,
    },

    /* Other ******************************************************************* */
    other: {
      city: {
        root: `${ROOTS.DASHBOARD}/city`,
        new: `${ROOTS.DASHBOARD}/city/new`,
        list: `${ROOTS.DASHBOARD}/city/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/city/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/city/${id}/edit`,
      },
      country: {
        root: `${ROOTS.DASHBOARD}/country`,
        new: `${ROOTS.DASHBOARD}/country/new`,
        list: `${ROOTS.DASHBOARD}/country/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/country/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/country/${id}/edit`,
      },
      currency: {
        root: `${ROOTS.DASHBOARD}/currency`,
        new: `${ROOTS.DASHBOARD}/currency/new`,
        list: `${ROOTS.DASHBOARD}/currency/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/currency/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/currency/${id}/edit`,
      },
      language: {
        root: `${ROOTS.DASHBOARD}/language`,
        new: `${ROOTS.DASHBOARD}/language/new`,
        list: `${ROOTS.DASHBOARD}/language/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/language/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/language/${id}/edit`,
      },
      region: {
        root: `${ROOTS.DASHBOARD}/region`,
        new: `${ROOTS.DASHBOARD}/region/new`,
        list: `${ROOTS.DASHBOARD}/region/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/region/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/region/${id}/edit`,
      },

    },

    /* Company ******************************************************************* */
    company: {
      company: {
        root: `${ROOTS.DASHBOARD}/company`,
        new: `${ROOTS.DASHBOARD}/company/new`,
        list: `${ROOTS.DASHBOARD}/company/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company/${id}/edit`,
      },

      companyWarehouse: {
        root: `${ROOTS.DASHBOARD}/company-warehouse`,
        new: `${ROOTS.DASHBOARD}/company-warehouse/new`,
        list: `${ROOTS.DASHBOARD}/company-warehouse/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse/${id}/edit`,
      },
      companyWarehouseProduct: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-product`,
        cards: `${ROOTS.DASHBOARD}/company-warehouse-product/cards`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-product/new`,
        list: `${ROOTS.DASHBOARD}/company-warehouse-product/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-product/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-product/${id}/edit`,
      },
      companyWarehouseDamagedProduct: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product`,
        cards: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/cards`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/new`,
        list: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/${id}/edit`,
      },
      companyWarehouseDamagedProductCategory: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/new`,
        list: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/${id}/edit`,
      },
      companyClient: {
        root: `${ROOTS.DASHBOARD}/company-client`,
        new: `${ROOTS.DASHBOARD}/company-client/new`,
        list: `${ROOTS.DASHBOARD}/company-client/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client/${id}/edit`,
        addClient: `${ROOTS.DASHBOARD}/company-client/add-client`,
        salesInvoicePayment: (companyClientId: string) => `${ROOTS.DASHBOARD}/company-client/${companyClientId}/sales-invoice-payment`,
      },
      companyClientCategory: {
        root: `${ROOTS.DASHBOARD}/company-client-category`,
        new: `${ROOTS.DASHBOARD}/company-client-category/new`,
        list: `${ROOTS.DASHBOARD}/company-client-category/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-category/${id}/edit`,
      },
      companyClientChain: {
        root: `${ROOTS.DASHBOARD}/company-client-chain`,
        new: `${ROOTS.DASHBOARD}/company-client-chain/new`,
        list: `${ROOTS.DASHBOARD}/company-client-chain/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-chain/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-chain/${id}/edit`,
      },
      companyClientGroup: {
        root: `${ROOTS.DASHBOARD}/company-client-group`,
        new: `${ROOTS.DASHBOARD}/company-client-group/new`,
        list: `${ROOTS.DASHBOARD}/company-client-group/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-group/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-group/${id}/edit`,
      },
      companyZone: {
        root: `${ROOTS.DASHBOARD}/company-zone`,
        new: `${ROOTS.DASHBOARD}/company-zone/new`,
        list: `${ROOTS.DASHBOARD}/company-zone/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-zone/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-zone/${id}/edit`,
      },
      companyPosition: {
        root: `${ROOTS.DASHBOARD}/company-position`,
        new: `${ROOTS.DASHBOARD}/company-position/new`,
        list: `${ROOTS.DASHBOARD}/company-position/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-position/${id}/edit`,
      },
      companyClientPosition: {
        root: `${ROOTS.DASHBOARD}/company-client-position`,
        new: `${ROOTS.DASHBOARD}/company-client-position/new`,
        list: `${ROOTS.DASHBOARD}/company-client-position/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-position/${id}/edit`,
      },
      companyWarehouseDamagedProductDisposeCategory: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/${id}/edit`,
      },
      companyWarehouseDamagedProductRestoreCategory: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/${id}/edit`,
      },
      companyWarehouseDamagedProductDispose: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose`,
        list: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose/${paramCase(id)}`,
        approvedPending: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose/approve-pending`,
      },
      companyWarehouseDamagedProductRestore: {
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore`,
        list: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore/${paramCase(id)}`,
        approvedPending: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore/approve-pending`,
  
    },
    companyClientBalanceChange: {
      root: `${ROOTS.DASHBOARD}/company-client-balance-change`,
      list: `${ROOTS.DASHBOARD}/company-client-balance-change/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/company-client-balance-change/${id}`,
      },
    },
    /* Client ******************************************************************* */
    client: {
      client: {
        root: `${ROOTS.DASHBOARD}/client`,
        new: `${ROOTS.DASHBOARD}/client/new`,
        list: `${ROOTS.DASHBOARD}/client/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client/${id}/edit`,
      },
      clientCategory: {
        root: `${ROOTS.DASHBOARD}/client-category`,
        new: `${ROOTS.DASHBOARD}/client-category/new`,
        list: `${ROOTS.DASHBOARD}/client-category/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-category/${id}/edit`,
      },
      clientChain: {
        root: `${ROOTS.DASHBOARD}/client-chain`,
        new: `${ROOTS.DASHBOARD}/client-chain/new`,
        list: `${ROOTS.DASHBOARD}/client-chain/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-chain/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-chain/${id}/edit`,
      },
      clientPosition: {
        root: `${ROOTS.DASHBOARD}/client-position`,
        new: `${ROOTS.DASHBOARD}/client-position/new`,
        list: `${ROOTS.DASHBOARD}/client-position/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-position/${id}/edit`,
      },
    },

    /* Product ******************************************************************* */
    product: {
      stock: {
        root: `${ROOTS.DASHBOARD}/stock`,
        cards: `${ROOTS.DASHBOARD}/stock/cards`,
      },
      productBrand: {
        root: `${ROOTS.DASHBOARD}/product-brand`,
        new: `${ROOTS.DASHBOARD}/product-brand/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-brand/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-brand/${id}/edit`,
      },
      product: {
        productSize: {
          productPackage: {
            root: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
            new: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/new`,
            details: (productId: string, productSizeId: string, productPackageId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/${productPackageId}`,
            edit: (productId: string, productSizeId: string, productPackageId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/${productPackageId}/edit`,
          },
          productVariant: {
            root: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
            new: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/new`,
            details: (productId: string, productSizeId: string, productVariantId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/${productVariantId}`,
            edit: (productId: string, productSizeId: string, productVariantId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/${productVariantId}/edit`,
          },
          root: (productId: string) => `${ROOTS.DASHBOARD}/product/${productId}`,
          new: (productId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/new`,
          details: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
          edit: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/edit`,
        },
        root: `${ROOTS.DASHBOARD}/product`,
        new: `${ROOTS.DASHBOARD}/product/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
        demo: {
          details: `${ROOTS.DASHBOARD}/product-category/${MOCK_ID}`,
          edit: `${ROOTS.DASHBOARD}/product-category/${MOCK_ID}/edit`,
        },
      },
      productCategory: {
        root: `${ROOTS.DASHBOARD}/product-category`,
        new: `${ROOTS.DASHBOARD}/product-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-category/${id}/edit`,
        demo: {
          details: `${ROOTS.DASHBOARD}/product-category/${MOCK_ID}`,
          edit: `${ROOTS.DASHBOARD}/product-category/${MOCK_ID}/edit`,
        },
      },
      productPackageName: {
        root: `${ROOTS.DASHBOARD}/product-package-name`,
        new: `${ROOTS.DASHBOARD}/product-package-name/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-package-name/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-package-name/${id}/edit`,
        demo: {
          details: `${ROOTS.DASHBOARD}/product-package-name/${MOCK_ID}`,
          edit: `${ROOTS.DASHBOARD}/product-package-name/${MOCK_ID}/edit`,
        },
      },
    },
    /* Provider ******************************************************************* */
    provider: {
      provider: {
        root: `${ROOTS.DASHBOARD}/provider`,
        new: `${ROOTS.DASHBOARD}/provider/new`,
        list: `${ROOTS.DASHBOARD}/provider/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/provider/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/provider/${id}/edit`,
        purchaseInvoicePayment: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/purchase-invoice-payment`,
        providerWarehouse: {
          root: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse`,
          new: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/new`,
          list: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/list`,
          details: (providerId: string, providerWarehouseId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/${providerWarehouseId}`,
          edit: (providerId: string, providerWarehouseId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/${providerWarehouseId}/edit`,
        },
      },
  
      providerBalanceChange: {
        root: `${ROOTS.DASHBOARD}/provider-balance-change`,
        list: `${ROOTS.DASHBOARD}/provider-balance-change/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/provider-balance-change/${id}`,
        },
    },
    /* Reports******************************************************************* */
    report: {
      productReport: {
        root: `${ROOTS.DASHBOARD}/product-report`,
      }
    },

    /* SalesSalesInvoice ******************************************************************* */
    invoice: {
      salesInvoice: {
        root: `${ROOTS.DASHBOARD}/sales-invoice`,
        new: `${ROOTS.DASHBOARD}/sales-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/sales-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/sales-invoice/${id}/edit`,
      },
      purchaseInvoice: {
        root: `${ROOTS.DASHBOARD}/purchase-invoice`,
        new: `${ROOTS.DASHBOARD}/purchase-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/purchase-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-invoice/${id}/edit`,
      },
      returnInvoice: {
        root: `${ROOTS.DASHBOARD}/return-invoice`,
        new: `${ROOTS.DASHBOARD}/return-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}/edit`,
        receiveApproved: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}/approve-received`,
      },
      providerReturnInvoice: {
        root: `${ROOTS.DASHBOARD}/provider-return-invoice`,
        new: `${ROOTS.DASHBOARD}/provider-return-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/provider-return-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/provider-return-invoice/${id}/edit`,
      },
      salesPaymentInvoiceAddonCategory: {
        root: `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category`,
        new: `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/new`,
        list: `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/${id}/edit`,
      },
      purchasePaymentInvoiceAddonCategory: {
        root: `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category`,
        new: `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/new`,
        list: `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/${id}/edit`,
      },
      salesPaymentInvoice: {
        root: `${ROOTS.DASHBOARD}/sales-payment-invoice`,
        new: `${ROOTS.DASHBOARD}/sales-payment-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice/${id}/edit`,
      },
      purchasePaymentInvoice: {
        root: `${ROOTS.DASHBOARD}/purchase-payment-invoice`,
        new: `${ROOTS.DASHBOARD}/purchase-payment-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice/${id}/edit`,
      },
      invoiceTag: {
        root: `${ROOTS.DASHBOARD}/invoice-tag`,
        new: `${ROOTS.DASHBOARD}/invoice-tag/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/invoice-tag/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/invoice-tag/${id}/edit`,
      },
    },

    /* WorkDay ******************************************************************* */
    workDay: {
      workDayPauseCategory: {
        root: `${ROOTS.DASHBOARD}/work-day-pause-category`,
        new: `${ROOTS.DASHBOARD}/work-day-pause-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day-pause-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day-pause-category/${id}/edit`,
      },
      workDayEndVisitCategory: {
        root: `${ROOTS.DASHBOARD}/work-day-end-visit-category`,
        new: `${ROOTS.DASHBOARD}/work-day-end-visit-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day-end-visit-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day-end-visit-category/${id}/edit`,
      },
      workDay: {
        root: `${ROOTS.DASHBOARD}/work-day`,
        new: `${ROOTS.DASHBOARD}/work-day/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day/${id}/edit`,
      },
    },


    /* Other ******************************************************************* */
    vehicle: {
      vehicle: {
        root: `${ROOTS.DASHBOARD}/vehicle`,
        new: `${ROOTS.DASHBOARD}/vehicle/new`,
        list: `${ROOTS.DASHBOARD}/vehicle/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${id}/edit`,
        vehicleExtra: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${id}/extra`,
      },
      vehicleBrand: {
        root: `${ROOTS.DASHBOARD}/vehicle-brand`,
        new: `${ROOTS.DASHBOARD}/vehicle-brand/new`,
        list: `${ROOTS.DASHBOARD}/vehicle-brand/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-brand/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle-brand/${id}/edit`,
      },
      vehicleModel: {
        root: `${ROOTS.DASHBOARD}/vehicle-model`,
        new: `${ROOTS.DASHBOARD}/vehicle-model/new`,
        list: `${ROOTS.DASHBOARD}/vehicle-model/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-model/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle-model/${id}/edit`,
      },
      vehicleExtraChange: {
        root: `${ROOTS.DASHBOARD}/vehicle-extra-change`,
        list: `${ROOTS.DASHBOARD}/vehicle-extra-change/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-extra-change/${paramCase(id)}`,
        receiveApproved: `${ROOTS.DASHBOARD}/vehicle-extra-change/approve-received`,
      },
    },

    /* Finance ******************************************************************* */
    finance: {

      exchangeRate: {
        root: `${ROOTS.DASHBOARD}/exchange-rate`,
        new: `${ROOTS.DASHBOARD}/exchange-rate/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/exchange-rate/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/exchange-rate/${id}/edit`,
        demo: {
          details: `${ROOTS.DASHBOARD}/exchange-rate/${MOCK_ID}`,
          edit: `${ROOTS.DASHBOARD}/exchange-rate/${MOCK_ID}/edit`,
        },
      },
      balanceChangeLog: {
        root: `${ROOTS.DASHBOARD}/balance-change-log`,
        new: `${ROOTS.DASHBOARD}/balance-change-log/new`,
        list: `${ROOTS.DASHBOARD}/balance-change-log/list`,
        details: (id: string) => `${ROOTS.DASHBOARD}/balance-change-log/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/balance-change-log/${id}/edit`,
      },
    },

    /* Task ******************************************************************* */
    task: {
      deliveryTask: {
        root: `${ROOTS.DASHBOARD}/delivery-task`,
        new: `${ROOTS.DASHBOARD}/delivery-task/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/edit`,
        assign: `${ROOTS.DASHBOARD}/delivery-task/assign`,
        dispatch: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/dispatch`,
        receiveApproved: (id: string, companyWarehouseId: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/approve-received/${companyWarehouseId}`,
      },
      deliveryTaskReturnCategory: {
        root: `${ROOTS.DASHBOARD}/delivery-task-return-category`,
        new: `${ROOTS.DASHBOARD}/delivery-task-return-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-return-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-return-category/${id}/edit`,
      },
      deliveryTaskItemReturnCategory: {
        root: `${ROOTS.DASHBOARD}/delivery-task-item-return-category`,
        new: `${ROOTS.DASHBOARD}/delivery-task-item-return-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-item-return-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-item-return-category/${id}/edit`,
      },
    },

    /* ******************************************************************* */
    /* ******************************************************************* */
    /* ******************************************************************* */
    /* ******************************************************************* */


    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
